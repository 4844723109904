<template>
    <div class="flex flex-col gap-3 items-center justify-center text-center px-4">
        <div class="w-16 h-16">
            <CoachingModeMarble />
        </div>
        <p class="body-1 text-[#8C8C8C] font-semibold">{{ dateToday }}</p>
        <div class="max-w-[800px]">
            <h1 class="heading-1 max-w-[500px]">
                <span class="gradient-text">Hi {{ userDetails.first_name ?? "there" }}, let's dig in</span>
                <br />
                What's on your mind?
            </h1>
        </div>
    </div>
    <div class="pt-14 flex justify-center">
        <div v-if="!useChatButton" class="max-w-[800px] grow">
            <BaseNotification v-if="error" theme="info" :text="error" @dismiss="error = null" />
            <ChatMessageControls
                :is-disabled="isTranscribing"
                is-paste-disabled
                :is-transcribing="isTranscribing"
                :default-text-input="!userDetails.prefers_default_to_voice"
                :is-sending="isSending"
                @text-send="handleTextSend"
                @text-keydown="handleTextKeydown"
                @recording-complete="handleRecordingComplete" />
        </div>
        <BaseButton v-else theme="startChat" @click="handleStartChatClick"><CoachingModeMarble class="w-8 h-8" /> Start Chat</BaseButton>
    </div>
    <div class="flex justify-center">
        <div class="flex gap-2 flex-nowrap overflow-x-auto p-4">
            <button
                v-for="{ id, start_url, external_name, chat_source } in badges"
                :key="id"
                type="button"
                class="rounded-xl bg-white border-2 border-[#E8E8E8] py-2 px-3 whitespace-nowrap body-1 font-semibold flex gap-2 items-center"
                @click="openUrl(start_url, { queryParams: { chat_source } })">
                {{ external_name }}<i class="bi bi-arrow-up-right text-xl leading-none icon-bold" />
            </button>
        </div>
    </div>
</template>

<script setup>
import ChatMessageControls from "~vue/ChatDetail/ChatMessageControls.vue";
import BaseButton from "~vue/components/BaseButton.vue";
import BaseNotification from "~vue/components/BaseNotification.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import { openUrl } from "~vue/utils";
import { CHATSOURCE } from "~vue/utils/constants";
import { logError } from "~vue/utils/logUtils";
import { DateTime } from "luxon";
import { computed, inject, ref } from "vue";

import { encodeAudio, transcribeRecording } from "../../transcription.js";

const { $sendEvent } = inject("globalProperties");
const userDetails = inject("user_details");

const emit = defineEmits(["transcription-error"]);

const { suggestedTopics, organizationPrompts, startChatUrl } = defineProps({
    suggestedTopics: { type: Array, default: () => [] },
    organizationPrompts: { type: Array, default: () => [] },
    startChatUrl: { type: String, default: () => "/coach/" },
    useChatButton: { type: Boolean, default: true },
});

const isTranscribing = ref(false);
const isSending = ref(false);
const error = ref(null);

const badges = computed(() => {
    const MAX_BADGES = 3;
    const MAX_SUGGESTED_TOPICS = 2;
    const topics = suggestedTopics.slice(0, MAX_SUGGESTED_TOPICS).map(({ prompt_id, start_url, external_name }) => ({
        id: prompt_id,
        start_url,
        external_name,
        chat_source: CHATSOURCE.HOME_SUGGESTED_TOPICS,
    }));
    const orgPrompts = organizationPrompts.map(({ id, start_url, external_name }) => ({
        id,
        start_url,
        external_name,
        chat_source: CHATSOURCE.TRENDING_ORG_PROMPT,
    }));
    return [...orgPrompts, ...topics].slice(0, MAX_BADGES);
});

const dateToday = DateTime.now().toFormat("LLL dd, yyyy");

const handleTextSend = ({ content }) => {
    // `isSending` is being used as a lock here to prevent creating multiple coaching sessions from
    // double-clicks on the submit button.
    if (isSending.value) {
        return;
    }
    isSending.value = true;

    error.value = null;

    openUrl(startChatUrl, {
        queryParams: {
            chat_source: CHATSOURCE.HOME_CHAT_MESSAGE,
            initial_message: content,
        },
    });
};

const handleStartChatClick = () => {
    openUrl(startChatUrl, { queryParams: { chat_source: CHATSOURCE.HOME_DEFAULT } });
};

const handleTextKeydown = () => {
    error.value = null;
};

const handleRecordingComplete = async ({ blob }) => {
    try {
        error.value = null;
        const encodedAudio = await encodeAudio(blob);
        const transcript = await transcribeRecording({
            encodedAudio,
            sendEvent: $sendEvent,
            onStart: () => {
                isTranscribing.value = true;
            },
            onError: () => {
                error.value = "Something went wrong. Please try again.";
                logError("Transcription request failed");
            },
        });
        isTranscribing.value = false;
        handleTextSend({ content: transcript });
    } catch (e) {
        error.value = "Something went wrong. Please try again.";
        logError(`Transcription request failed: ${e}`);
        isTranscribing.value = false;
        emit("transcription-error");
    }
};
</script>

<style scoped></style>
