<template>
    <CenterBubble position="middle" class="!scale-50 !scale-x-100" />
    <div class="antialiased overflow-scroll py-6 px-0 md:px-16">
        <ScheduleFollowupDialog ref="scheduleFollowupDialog" :coach-followup-events="coach_followup_events" />
        <div class="mx-auto flex flex-col gap-16 max-w-[1200px] px-6 md:px-0">
            <div class="flex flex-col gap-2">
                <div class="flex items-center justify-between">
                    <span>
                        <PrivacyDialog button-text="Privacy" />
                    </span>
                    <div class="flex items-center gap-2 mb-2">
                        <BaseTooltip>
                            <template #trigger>
                                <button
                                    class="rounded-full w-12 h-12 flex justify-center items-center border-2 border-[#E8E8E8]"
                                    @click="scheduleFollowupDialog?.open({ showCalendar: true })">
                                    <Calendar foreground-class="stroke-[#555BA2]" class="w-6 h-6" />
                                </button>
                            </template>
                            <template #content>Schedule</template>
                        </BaseTooltip>
                        <BaseTooltip>
                            <template #trigger>
                                <button
                                    class="gradient-border w-12 h-12 rounded-full flex items-center justify-center"
                                    @click="openUrl(default_chat_redirect_url, { queryParams: { chat_source: CHATSOURCE.HOME_DEFAULT } })">
                                    <i class="bi bi-plus-lg leading-none gradient-text icon-bold text-2xl"></i>
                                </button>
                            </template>
                            <template #content>Start Chat</template>
                        </BaseTooltip>
                    </div>
                </div>
                <GlobalNoticeBanner />
                <HomeDisclaimerMessage :message="disclaimer_message" />
            </div>
            <div class="max-w-[926px] mb-10 mx-auto w-full">
                <HomeChatStarter :suggested-topics="suggested_topics" :organization-prompts="organization_prompts" :start-chat-url="default_chat_redirect_url" />
            </div>
            <HomeWrapped v-if="can_view_wrapped" :user-conversation-count="user_conversation_count" :start-chat-url="default_chat_redirect_url" :wrapped-url="wrapped_url" />
            <HomePersonalization v-if="!can_view_wrapped && !has_answered_onboarding_questions && user_conversation_count < 4" :onboarding-question-url="onboarding_question_url" />
            <HomeActionItemsSection v-if="action_items.items?.length > 0" :action-items-data="action_items" :default-chat-url="default_chat_redirect_url" />
            <HomeNewUserChecklist
                :new-user-checklist-items="new_user_checklist"
                :user-created-at="userDetails.user_created_at"
                :organization-prompt-title="organization_prompts[0]?.external_name"
                :organization-prompt-url="organization_prompts[0]?.start_url"
                :organization-icon-src="organizationDetails?.logo_url"
                :default-chat-url="default_chat_redirect_url"
                :insight-chat-url="user_insights[0]?.chat_url"
                @schedule="(eventProps) => scheduleFollowupDialog?.open({ eventData: eventProps?.eventData, showCalendar: true })" />
            <HomeCoachingInsightsSection :user-insights="user_insights" :default-chat-url="default_chat_redirect_url" />
            <div class="grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 md:gap-10 w-full">
                <HomeSuggestedTopicsSection :topics="suggested_topics" />
                <HomeSkillBuildingPlanSection v-if="skills && skills.length > 0" :skills="skills" />
            </div>
            <TrendingAtOrganizationSection
                :organization-name="organizationDetails.name"
                :organization-prompts="organization_prompts"
                :organization-icon-img-src="organizationDetails.logo_url"
                :is-default-organization="organizationDetails.is_default" />
            <!-- internal only feature -->
            <div v-if="review_enabled" class="grid grid-cols-12 gap-4 mx-auto w-full order-last">
                <div class="col-span-12 md:col-span-4">
                    <HomeReview :review="review" />
                </div>
            </div>
        </div>
    </div>
    <CookieBanner />
</template>

<script>
import LeftSidebarLayout from "~vue/layouts/LeftSidebarLayout.vue";
export default { layout: LeftSidebarLayout };
</script>

<script setup>
import HomeActionItemsSection from "~vue/ChatHome/HomeActionItemsSection.vue";
import HomeChatStarter from "~vue/ChatHome/HomeChatStarter.vue";
import HomeCoachingInsightsSection from "~vue/ChatHome/HomeCoachingInsightsSection.vue";
import HomeDisclaimerMessage from "~vue/ChatHome/HomeDisclaimerMessage.vue";
import HomeNewUserChecklist from "~vue/ChatHome/HomeNewUserChecklist.vue";
import HomePersonalization from "~vue/ChatHome/HomePersonalization.vue";
import HomeReview from "~vue/ChatHome/HomeReview.vue";
import HomeSkillBuildingPlanSection from "~vue/ChatHome/HomeSkillBuildingPlanSection.vue";
import HomeSuggestedTopicsSection from "~vue/ChatHome/HomeSuggestedTopicsSection.vue";
import HomeWrapped from "~vue/ChatHome/HomeWrapped.vue";
import TrendingAtOrganizationSection from "~vue/ChatHome/TrendingAtOrganizationSection.vue";
import BaseTooltip from "~vue/components/BaseTooltip.vue";
import CenterBubble from "~vue/components/CenterBubble.vue";
import CookieBanner from "~vue/components/CookieBanner.vue";
import ScheduleFollowupDialog from "~vue/components/ScheduleFollowupDialog.vue";
import GlobalNoticeBanner from "~vue/GlobalNoticeBanner.vue";
import Calendar from "~vue/icons/Calendar.vue";
import PrivacyDialog from "~vue/Onboarding/PrivacyDialog.vue";
import { openUrl } from "~vue/utils";
import { CHATSOURCE } from "~vue/utils/constants.js";
import { defineProps, inject, onMounted, onUnmounted, ref } from "vue";

const globalProperties = inject("globalProperties");
const organizationDetails = inject("organization_details");
const userDetails = inject("user_details");
const scheduleFollowupDialog = ref(null);

const props = defineProps({
    review_enabled: { type: Boolean },
    review: { type: Object, default: null },
    default_chat_redirect_url: { type: String, default: "/" },
    disclaimer_message: { type: String, default: null },
    user_insights: { type: Array, default: () => [] }, // holds an array of insight objects, containing id, text, chat_url
    suggested_topics: { type: Array, default: () => [] },
    skills: { type: Array, default: () => [] },
    action_items: { type: Object, default: () => {} },
    event_context: { type: Object, default: () => {} },
    coach_followup_events: { type: Array, default: () => [] },
    user_conversation_count: { type: Number, default: 0 },
    new_user_checklist: {
        type: Object,
        default: () => ({
            chat: false,
            mic: false,
            schedule: false,
            "role-play": false,
            upload: false,
            organization: false,
            insight: false,
        }),
    },
    organization_prompts: { type: Array, default: () => [] },
    has_answered_onboarding_questions: { type: Boolean, default: true },
    onboarding_question_url: { type: String, default: undefined },
    can_view_wrapped: { type: Boolean, default: false },
    wrapped_url: { type: String, default: "/wrapped/" },
});

onMounted(() => {
    globalProperties.$setEventContext(props.event_context);
    const params = new URLSearchParams(window.location.search);
    if (params.get("schedule_followup")) {
        scheduleFollowupDialog.value.open();
    }
});

onUnmounted(() => {
    globalProperties.$setEventContext({});
});
</script>
