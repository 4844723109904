<template>
    <HomeSectionContainer v-if="shouldShowChecklist">
        <template #title>
            <div class="grow flex items-center justify-between md:justify-start gap-6">
                <div>
                    <p class="text-base font-semibold tracking-tight text-[#8C8C8C]">Your first week</p>
                    <h3 class="text-2xl font-semibold tracking-tight">Getting the most out of Nadia</h3>
                </div>
                <span class="h-10 w-10">
                    <ProgressCircle :progress="progress.percent" :content="progress.content" />
                </span>
            </div>
        </template>
        <div class="flex gap-2 flex-wrap md:flex-nowrap overflow-x-auto">
            <div
                v-for="{ key, iconClass, component, title } in checklist"
                :key="key"
                class="w-full relative rounded-lg p-3 border-2 bg-white border-[#E8E8E8] min-w-40 cursor-pointer"
                :class="{ '!border-[#B7EB8F] !bg-[#F6FFED] !text-[#2AB756]': checklistItemIsDone(key) }"
                @click="() => onChecklistItemClick(key)">
                <i v-if="checklistItemIsDone(key)" class="bi bi-check-lg text-xl md:text-2xl float-right md:absolute md:top-0 md:right-2"></i>
                <div class="flex md:flex-col items-center md:items-start gap-3 h-full">
                    <template v-if="key === 'organization' && organizationPromptTitle">
                        <img v-if="organizationIconSrc" :src="organizationIconSrc" alt="Organization icon" class="max-h-8 w-fit mb-2" />
                        <i class="bi bi-people md:text-2xl"></i>
                        <span class="font-semibold">Try a {{ organizationPromptTitle }}</span>
                    </template>
                    <template v-else>
                        <div v-if="component" class="w-6 h-6">
                            <component :is="component" />
                        </div>
                        <i v-else :class="iconClass" class="md:text-xl"></i>
                        <span class="font-semibold">{{ title }}</span>
                    </template>
                </div>
            </div>
        </div>
    </HomeSectionContainer>
</template>

<script setup>
import { usePermission } from "@vueuse/core";
import ReflectiveCoachMarble from "~vue/components/navigation/CoachingModeMarbles/ReflectiveCoachMarble.vue";
import RolePlayCoachMarble from "~vue/components/navigation/CoachingModeMarbles/RolePlayCoachMarble.vue";
import SkillBuildingCoachMarble from "~vue/components/navigation/CoachingModeMarbles/SkillBuildingCoachMarble.vue";
import ProgressCircle from "~vue/components/ProgressCircle.vue";
import { openUrl } from "~vue/utils";
import { CHATSOURCE } from "~vue/utils/constants.js";
import { DateTime } from "luxon";
import { computed } from "vue";

import { nextWorkday, roundToNearest15 } from "../../dateUtils.js";
import HomeSectionContainer from "./HomeSectionContainer.vue";

const firstWeekChecklist = [
    { key: "schedule", iconClass: "bi bi-calendar", title: "Schedule your next check-in" },
    { key: "reflect", component: ReflectiveCoachMarble, title: "Check-in daily with a 2 minute reflection" },
    { key: "commit", component: SkillBuildingCoachMarble, title: "Commit to 10 min of coaching this week" },
    { key: "role-play", component: RolePlayCoachMarble, title: "Try a role-play with Nadia" },
    { key: "insight", iconClass: "bi bi-lock", title: "Unlock a new Nadia insight" },
];

const micPermission = usePermission("microphone");
const micPermissionGranted = computed(() => micPermission.value === "granted");

const emit = defineEmits(["schedule"]);

const { userCreatedAt, newUserChecklistItems, organizationPromptTitle, organizationIconSrc, defaultChatUrl } = defineProps({
    newUserChecklistItems: {
        type: Object,
        default: () => ({
            chat: { is_done: false },
            mic: { is_done: true },
            schedule: { is_done: false },
            "role-play": { is_done: false },
            upload: { is_done: false },
            organization: { is_done: false },
            insight: { is_done: false },
            reflect: { is_done: false },
            commit: { is_done: false },
        }),
    },
    userCreatedAt: { type: String, default: undefined },
    organizationPromptTitle: { type: String, default: undefined },
    organizationPromptUrl: { type: String, default: undefined },
    organizationIconSrc: { type: String, default: undefined },
    defaultChatUrl: { type: String, default: undefined },
    insightChatUrl: { type: String, default: undefined },
});

const userCreatedAtDate = computed(() => DateTime.fromISO(userCreatedAt));
const isUserFirstMonth = computed(() => {
    return userCreatedAtDate.value.isValid && userCreatedAtDate.value.plus({ months: 1 }) > DateTime.now();
});

// left this in case we want more dynamic checklist logic again, for now it will directly reference the first week checklist
const checklist = firstWeekChecklist;

const checklistItemIsDone = computed(() => {
    return (key) => {
        // We need to check this on the client side as well as what was sent over from the server
        if (key === "mic") {
            return micPermissionGranted.value || newUserChecklistItems[key]?.is_done;
        }

        return newUserChecklistItems[key]?.is_done;
    };
});

const progress = computed(() => {
    const numCompleted = checklist.filter(({ key }) => checklistItemIsDone.value(key)).length;

    const total = checklist.length;
    return {
        percent: (numCompleted / total) * 100,
        content: `${numCompleted}/${total}`,
    };
});

const shouldShowChecklist = computed(() => progress.value.percent < 100 && isUserFirstMonth.value);

const eventDataForCommit = {
    event_at_confirmed: nextWorkday(roundToNearest15(DateTime.now())).toISO(),
    invite_description: "Let's discuss what's coming up for you this week.",
    recurring: true,
    id: 0,
};

const onChecklistItemClick = (key) => {
    switch (key) {
        case "chat":
        case "mic":
        case "role-play":
        case "upload":
        case "organization":
        case "insight":
        case "reflect":
            if (newUserChecklistItems[key]?.start_url) {
                openUrl(newUserChecklistItems[key].start_url, { queryParams: { chat_source: CHATSOURCE.NEW_USER_CHECKLIST } });
            } else {
                openUrl(defaultChatUrl, { queryParams: { chat_source: CHATSOURCE.NEW_USER_CHECKLIST } });
            }
            break;
        case "schedule":
            // opens calendar scheduling dialog in ChatHome.vue
            emit("schedule");
            break;
        case "commit":
            emit("schedule", { eventData: eventDataForCommit });
            break;
        default:
            break;
    }
};
</script>

<style scoped></style>
